<template>
  <div class="growth">
    <transition name="slide">
      <div v-if="isTourActive" class="growth__tour">
        <c-list display="flex">
          <c-list-item
            v-for="(step, index) in activeTour.tour_steps"
            :key="step.target"
          >
            <c-pseudo-box
              as="a"
              color="#fff"
              fontWeight="500"
              px="3"
              py="3"
              borderRadius="2px"
              cursor="pointer"
              :fontSize="{ lg: 'sm', xl: 'base' }"
              :_hover="{
                textDecoration: 'none',
              }"
              :bg="(tour ? tour.currentStep : 0) === index ? '#1A86D0' : ''"
            >
              {{ step.title }}
            </c-pseudo-box>
          </c-list-item>
        </c-list>
        <c-button variant-color="vc-orange" color="#fff" @click="onExitClick"
          >Exit</c-button
        >
      </div>
    </transition>
    <div
      style="justify-content: flex-end; margin: -5px 15px 7px 0px"
      class="hidden-md-and-up"
    >
      <HeaderOptions />
    </div>
    <div class="growth__header">
      <ul class="growth__header__links">
        <li>
          <c-link
            as="router-link"
            exact-active-class="active"
            to="/app/growth-framework"
            exact-path-active-class="active"
            exact-path
          >
            <svg>
              <use href="@/assets/icons/growth-line.svg#growth"></use>
            </svg>
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/foundation"
            active-class="active"
          >
            Foundation
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/business-model"
            active-class="active"
          >
            Business Model
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/competition"
            active-class="active"
          >
            Competition
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/market-potential"
            active-class="active"
          >
            Go to Market
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/risk-assessment"
            active-class="active"
          >
            Risk Assessment
          </c-link>
        </li>
        <li>
          <c-link
            as="router-link"
            to="/app/growth-framework/overview"
            active-class="active"
          >
            AI Generate
          </c-link>
        </li>
      </ul>
      <div style="margin-top: -20px" class="hidden-sm-and-down">
        <HeaderOptions />
      </div>
    </div>
    <div class="growth__content">
      <router-view></router-view>
    </div>
    <v-tour
      name="growthTour"
      :steps="activeTour ? activeTour.tour_steps : []"
      :callbacks="tourCallBacks"
      :options="{ highlight: true }"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <TourStep
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :finish="tour.finish"
            :stop="tour.stop"
            :skip="tour.skip"
            :highlight="tour.highlight"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <c-flex slot="header" justify="space-between" mb="3">
              <c-text fontSize="xs">
                Step {{ tour.currentStep + 1 }} of {{ tour.steps.length }}
              </c-text>
              <c-icon-button
                variant-color="white"
                bg="#fff"
                color="#0D598D"
                aria-label="Stop Tour"
                :isRound="true"
                size="sm"
                minWidth="15px"
                w="16px"
                h="16px"
                icon="small-close"
                @click="onExitClick"
              />
            </c-flex>
            <c-box slot="content" w="100%" my="3" textAlign="left">
              <c-heading fontSize="sm" fontWeight="600" mb="2">
                {{ tour.steps[tour.currentStep].title }}
              </c-heading>
              <c-text fontSize="xs">
                {{ tour.steps[tour.currentStep].description }}
              </c-text>
            </c-box>
            <div slot="actions" class="v-step__actions">
              <c-button
                v-if="!tour.isFirst"
                @click="onTourPreviousStepClick(tour.currentStep)"
                variant-color="white"
                size="sm"
                variant="ghost"
                fontWeight="500"
              >
                Back
              </c-button>
              <c-button
                v-if="tour.isLast"
                @click="tour.finish"
                variant-color="white"
                bg="#fff"
                size="sm"
                variant="solid"
                color="#000"
                fontWeight="500"
              >
                Finish
              </c-button>
              <c-button
                v-else
                @click="onTourNextStepClick(tour.currentStep)"
                variant-color="white"
                bg="#fff"
                size="sm"
                variant="solid"
                color="#000"
                fontWeight="500"
                py="2"
              >
                <c-text maxWidth="100%" whiteSpace="normal">
                  Next: {{ tour.steps[tour.currentStep + 1].title }}
                </c-text>
              </c-button>
            </div>
          </TourStep>
        </transition>
      </template>
    </v-tour>

    <PendingInvitation @closeInvites="closeModal" v-if="isPendingInvitesOpen" />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { getCustomers } from '@/services/business-model';
import { mapState, mapActions, mapMutations } from 'vuex';
import TourStep from './components/AppTourStep.js';
import PendingInvitation from './components/PendingInvitation.vue';
import { getCompetitors } from '@/services/competition.js';
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import { getNotifications } from '@/services/user.js';
import { getTourData } from '@/services/common';

export default {
  components: {
    TourStep,
    HeaderOptions,
    PendingInvitation,
  },
  data() {
    return {
      isMenuOpen: false,
      isPendingInvitesOpen: false,
      tourCallBacks: {
        onNextStep: function () {
          // const nextStepIndex = currentStep + 1;
          // if (!this.isOnStepPage(this.activeTour.steps[nextStepIndex])) {
          // }
        },
        onFinish: () => {
          this.stopTour();
        },
      },
    };
  },
  computed: {
    ...mapState('tour', {
      isTourActive: (state) => state.isTourActive,
      activeTour: (state) => state.activeTour,
      activeStepIndex: (state) => state.activeStepIndex,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapState('auth', {
      pending_invites: (state) => state.pending_invites,
    }),
    tour() {
      return this.$tours['growthTour'] || null;
    },
  },
  async created() {
    this.stopTour();
    this.getCompetitors();
    this.getNotifications();
    this.getPendingInvitations();
    await this.getTourData();
  },
  mounted() {
    getCustomers(this.activeCompany.id).then((res) => {
      this.setCustomers(res.data.customer);
    });
  },
  methods: {
    ...mapActions({
      stopTour: 'tour/stopTour',
      setCustomers: 'company/setCustomers',
    }),
    ...mapMutations({
      setCompetitors: 'company/setCompetitors',
      setIsFetchingCompetitors: 'company/setIsFetchingCompetitors',
      setHasFetchedCompetitors: 'company/setHasFetchedCompetitors',
      setCompletionData: 'company/setCompletionData',
      setNotifications: 'company/setNotifications',
      setActiveStepIndex: 'tour/setActiveStepIndex',
      setTourStages: 'tour/setTourStages',
      updateUser: 'auth/updateUser',
    }),
    closeModal() {
      this.isPendingInvitesOpen = false;
    },
    getPendingInvitations() {
      this.isPendingInvitesOpen =
        this.pending_invites.length > 0 ? true : false;
    },
    getTourData() {
      // this.isFetchingTourData = true;
      return getTourData().then((res) => {
        this.setTourStages(res.data.tour);
        // this.isFetchingTourData = false;
      });
    },
    onExitClick() {
      this.stopTour();
    },
    isOnStepPage(step) {
      return this.$route.path.includes(step.route);
    },
    onTourNextStepClick(currentStep) {
      const nextStepIndex = currentStep + 1;
      const nextStep = this.activeTour.tour_steps[nextStepIndex];
      if (this.isOnStepPage(nextStep)) {
        this.tour.nextStep();
        this.setActiveStepIndex(nextStepIndex);
      } else {
        this.$router.push(`/app/growth-framework${nextStep.route}`, () => {
          this.$nextTick(() => {
            this.tour.nextStep();
            this.setActiveStepIndex(nextStepIndex);
          });
        });
      }
    },
    onTourPreviousStepClick(currentStep) {
      const previousStepIndex = currentStep - 1;
      const previousStep = this.activeTour.tour_steps[previousStepIndex];
      if (this.isOnStepPage(previousStep)) {
        this.tour.previousStep();
        this.setActiveStepIndex(previousStepIndex);
      } else {
        this.$router.push(`/app/growth-framework${previousStep.route}`, () => {
          this.$nextTick(() => {
            this.tour.previousStep();
            this.setActiveStepIndex(previousStepIndex);
          });
        });
      }
    },
    getCompetitors() {
      if (!this.activeCompany) {
        // wait for activeCompany in case it's not set when this function is called
        const unwatchActiveCompany = this.$watch('activeCompany', () => {
          unwatchActiveCompany();
          this.getCompetitors();
        });
        return;
      }
      this.setIsFetchingCompetitors(true);
      getCompetitors(this.activeCompany.id)
        .then((res) => {
          this.setIsFetchingCompetitors(false);
          this.setCompetitors(cloneDeep(res.data.competitor));
          this.setHasFetchedCompetitors(true);
        })
        .catch(() => {
          this.setIsFetchingCompetitors(false);
        });
    },
    getNotifications() {
      getNotifications().then((res) => {
        this.setNotifications(res.data.notification);
      });
    },
  },
  watch: {
    isTourActive() {
      setTimeout(() => {
        if (this.isTourActive) {
          const isOnRoute =
            this.$route.path ===
            `/app/growth-framework${
              this.activeTour.tour_steps[this.activeStepIndex].route
            }`;
          if (isOnRoute) {
            this.tour.start(this.activeStepIndex);
          } else {
            this.$router.push(
              `/app/growth-framework${
                this.activeTour.tour_steps[this.activeStepIndex].route
              }`,
              () => {
                this.$nextTick(() => {
                  this.tour.start(this.activeStepIndex);
                });
              }
            );
          }
        } else {
          this.tour.stop();
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.growth {
  @apply h-full;
  &__header {
    @apply flex justify-between;
    align-items: center;

    &__links {
      @apply flex list-none overflow-x-scroll;
      background-color: #eaf0f6;
      li {
        // @apply flex-1;
        // &:first-of-type {
        //   max-width: 60px;
        // }
        a {
          display: flex;
          align-items: center;
          @apply p-4 font-medium text-center;
          width: max-content;
          &:focus {
            @apply shadow-none;
          }
          &:hover,
          &.active {
            @apply no-underline;
            color: #fff;
            outline: none;
            background-color: $color-orange;
            svg {
              fill: #fff;
            }
          }
          svg {
            width: 1.5em;
            height: 1.5em;
          }
        }
      }
    }
  }
  &__content {
    margin-top: 40px;
    height: calc(100% - 90px);
  }
  &__tour {
    @apply fixed top-0 left-0 right-0 flex items-center justify-between px-14 z-20;
    height: 100px;
    background-color: #021a2b;
  }

  @screen md {
    &__header {
      height: 80px;
      &__links {
        @apply mr-4 h-full;
        max-width: calc(100% - 40px);
        li {
          @apply h-full;
          a {
            @apply h-full w-full;
          }
        }
      }
    }
  }
  @screen lg {
    &__tour {
      @apply left-24 right-0;
    }
  }
  .v-tour {
    .v-step {
      background-color: rgba(13, 90, 141, 0.95);
      &__actions {
        @apply flex justify-between;
      }
    }
    &__target--highlighted {
      box-shadow: 0 3px 20px rgba(26, 131, 201, 0.418);
      z-index: initial;
    }
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slide-enter-active {
  animation: slideInDown 0.4s;
}
.slide-leave-active {
  animation: slideOutUp 0.3s;
}

.mobile-btn .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.mobile-btn .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.mobile-btn .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.mobile-btn {
  @apply absolute;
  right: 10px;
  top: 15px;
  svg {
    width: 3em;
    height: 3em;
  }
}
</style>
